<template>
  <v-container class="text-center">
    <v-row>
      <v-col cols="10" offset="1">
        <v-icon class="sucess_icon my-5" color="red">error_outline</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" offset="1"> <h3>Unable to process!</h3> </v-col>

      <v-col cols="10" offset="1">
        <p class="my-5 caption">{{ this.$route.query.message }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>
<style scoped>
.sucess_icon {
  font-size: 50px;
}
</style>
